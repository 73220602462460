


import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

const MAX_SELECTABLE_LANGUAGES = 3;

type TEventLangSelectorLanguage = {
  twoLetterCode: string;
  necessary?: boolean;
  selected: boolean;
}

type TVueClassNamesObject = {
  [key: string]: boolean;
}

@Component
export default class EventLanguageSelector extends Vue {

  public isPreSelectedLanguagePropProcessed: boolean = false;
  public isOptionsListShown: boolean = false;
  public maxSelectableLanguages: number = MAX_SELECTABLE_LANGUAGES; // Needed in HTML part
  public languagesList: TEventLangSelectorLanguage[] = [
    {
      twoLetterCode: 'en',
      necessary: true,
      selected: true,
    },
    {
      twoLetterCode: 'lt',
      selected: false,
    },
    {
      twoLetterCode: 'ru',
      selected: false,
    },
    {
      twoLetterCode: 'zh',
      selected: false,
    },
    {
      twoLetterCode: 'uk',
      selected: false,
    },
    {
      twoLetterCode: 'vi',
      selected: false,
    },
    {
      twoLetterCode: 'bg',
      selected: false,
    },
    {
      twoLetterCode: 'sq',
      selected: false,
    },
    {
      twoLetterCode: 'sr',
      selected: false,
    },
  ];
  public selectedLanguages: TEventLangSelectorLanguage[] = [];

  public get selectedLanguagesQuantity(): number {
    return this.necessaryLanguages.length + this.selectedLanguages.length;
  }

  public get necessaryLanguages(): TEventLangSelectorLanguage[] {
    return this.languagesList.filter((lang: TEventLangSelectorLanguage) => {
      return lang.necessary === true;
    });
  }

  public get availableLanguages(): TEventLangSelectorLanguage[] {
    return this.languagesList.filter((lang: TEventLangSelectorLanguage) => {
      return !lang.necessary;
    });
  }

  @Prop({type: Array, default: []})
  public readonly preSelectedLanguages: string[]

  @Prop({type: Boolean, default: false})
  public readonly readOnly: boolean;

  @Watch('preSelectedLanguages')
  private onPreSelectedLanguagesChange(newValue: Array<string>): void {
    this.initializeSelectedLanguageOnce(newValue);
  }

  public get resultingLanguageCodes(): string[] {
    return this.necessaryLanguages
      .concat(this.selectedLanguages)
      .map(lang => lang.twoLetterCode);
  }

  public mounted(): void {
    document.addEventListener('click', this.hideOptionsList);
  }

  public beforeDestroy(): void {
    document.removeEventListener('click', this.hideOptionsList);
  }

  private showOptionsList(): void {
    this.isOptionsListShown = true;
  }

  private hideOptionsList(): void {
    this.isOptionsListShown = false;
  }

  private toggleOptionsList(): void {
    this.isOptionsListShown = !this.isOptionsListShown;
  }

  private initializeSelectedLanguageOnce(input: string[]): void {
    // TODO: refactor
    // This is to be run only once, otherwise recursion may occur.
    if (this.isPreSelectedLanguagePropProcessed) {
      return;
    }

    for (let i = 0; i < input.length; i++) {
      for (let j = 0; j < this.languagesList.length; j++) {
        if (input[i] === this.languagesList[j].twoLetterCode && !this.languagesList[j].necessary) {
          this.onOptionClick(this.languagesList[j]);
        }
      }
    }
    this.isPreSelectedLanguagePropProcessed = true;
  }

  private onOptionClick(lang: TEventLangSelectorLanguage): void {

    if (lang.necessary || (!lang.selected && this.selectedLanguagesQuantity >= MAX_SELECTABLE_LANGUAGES)) {
      return;
    }

    // Without this, first click on any option does two clicks because the watcher is getting run.
    this.isPreSelectedLanguagePropProcessed = true;

    if (lang.selected) {
      this.deselectLanguage(lang);
    } else {
      lang.selected = true;
      this.selectedLanguages.push(lang);
      this.emitResultingLanguages();
    }

  }

  private emitResultingLanguages(): void {
    this.$emit('eventLanguagesUpdated', this.resultingLanguageCodes);
  }

  private deselectLanguage(lang: TEventLangSelectorLanguage): void {
    lang.selected = false;
    this.selectedLanguages = this.selectedLanguages.filter(x => x.twoLetterCode !== lang.twoLetterCode);
    this.emitResultingLanguages();
  }

  private getOptionClassNames(lang: TEventLangSelectorLanguage): TVueClassNamesObject {
    const result: TVueClassNamesObject = {};

    // Dynamic class for styling each language separately
    result['custom-option-' + lang.twoLetterCode] = true; // We always need this dynamic className

    // Is it currently selected?
    result['custom-option-selected'] = lang.selected;

    // Make options unclickable after choice limit has been reached
    result['custom-option-unavailable'] = !lang.selected && (this.selectedLanguagesQuantity >= MAX_SELECTABLE_LANGUAGES) && !lang.necessary;

    return result;
  }

}
